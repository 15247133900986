<template>
  <div class="products__filter m-md-t-2">
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myCustomers.options') }}
          </div>
        </template>

        <div class="form-group">
          <select
            :value="sortOption.key"
            @change="sortChange($event)"
          >
            <option
              v-for="option of visibleOptions"
              :key="option.key"
              :value="option.key"
              :selected="option.key===sortOption.key"
            >
              <!-- Future note, even though :value binding on select works, without the :selected attr on individual
               options it will not work for options that are added
               (probably to do with vue trying to set the value before adding the option ) -->
              {{ $t(`account.account.myCustomers.${option.id}`) }} {{ !option.invert? '↓' : '↑' }}
            </option>
          </select>
        </div>
      </collapse>
    </fieldset>
  </div>
</template>
<script>
import collapse from '@/components/collapse.vue';
import { mapState } from 'vuex';

const sourceSortOptions = [].concat(...[
  [false, 'modifiedAt'],
  [false, 'createdAt'],
  [false, 'name'],
  [true, 'lastName'],
  [true, 'email'],
  [true, 'phone'],
  [true, 'language'],
].map(
  ([hidden, id]) => [false, true].map((invert) => ({
    id,
    hidden,
    invert,
    key: JSON.stringify({ id, invert }),
  })),
));

export default {
  components: {
    collapse,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        sort: 'modifiedAt',
        filters: [],
        externalid: '',
        addressid: '',
        showHidden: false,
      }),
    },
  },
  data() {
    return {
      sortOptions: sourceSortOptions,
    };
  },
  computed: {
    valueInt: {
      get() {
        return this.value;
      },
      set(newval) {
        this.$emit('input', newval);
      },
    },
    ...mapState({
      customer: (state) => state.customer,
    }),
    sortOption() {
      const { valueInt, sortOptions } = this;
      return this.sortOptionOf(valueInt.sort) ?? sortOptions[0];
    },
    visibleOptions() {
      const { sortOption, sortOptions } = this;
      return sortOptions.filter((x) => !x.hidden || (x.key === sortOption.key));
    },
  },
  methods: {
    sortOptionOf(sort) {
      const { sortOptions } = this;
      const key = sort?.key ?? (sort ? JSON.stringify({ id: sort.id, invert: !!sort.invert }) : sortOptions[0].key);
      return sortOptions.find((k) => k.key === key);
    },
    sortChange(event) {
      const option = this.sortOptions.find((k) => k.key === event.target.value);
      this.valueInt = {
        ...this.valueInt,
        sort: option,
      };
    },
  },
};
</script>
